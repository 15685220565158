import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import PropTypes from "prop-types";
import { Container, Row, Col } from "glamorous-grid";
import g from "glamorous";
import { css } from "glamor";
import Menu from "../components/Menu-en";
import { Helmet } from "react-helmet";
import ContactForm from "../components/ContactForm-en";
import Footer from "../components/Footer-en";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { FaHome } from "react-icons/fa";

const Wrapper = g.main({
  maxWidth: `1050px`,
  margin: `0 auto 4em`,
});

const H1 = g.h1({
  textAlign: `center`,
  color: `#3c4d68`,
  maxWidth: `840px`,
  margin: `1.4em auto .8em`,
  lineHeight: `1.25em`,
  fontSize: `40px`,
  "@media(max-width: 767px)": {
    fontSize: `28px`,
  },
});

const Card = g.section({
  backgroundColor: `white`,
  marginBottom: `2em`,
  borderRadius: `6px`,
  border: `1px solid rgba(73,105,126,0.08)`,
  boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
  transition: `all 0.15s ease-in-out`,
  ":hover": {
    border: `1px solid rgba(73,105,126,0.18)`,
    boxShadow: `0 1px 10px rgba(0,0,0,.26)`,
    transition: `all 0.15s ease-in-out`,
    transform: `translateY(-2px)`,
  },
});

const Title = g.h2({
  padding: `0 .5em 0 .82em`,
  margin: `.7em 0 .3em`,
  font: `700 18px/1.35em -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
  minHeight: `2.4em`,
});

const Text = g.p({
  padding: `0 .5em 0 .8em`,
  marginBottom: `.6em`,
  color: `#1f1f1f`,
});

const Breadcrumb = g.ul({
  fontSize: `13px`,
  lineHeight: `20px`,
  marginLeft: `0 !important`,
  marginTop: `0`,
  marginBottom: `10px !important`,
  fontWeight: `500`,
  letterSpacing: `.015em`,
});

const BreadcrumbItem = g.li({
  display: `inline`,
  "&:first-of-type": {
    "&::before": {
      content: `none`,
    },
  },
  "&::before": {
    content: `/`,
    padding: `0 .5em`,
  },
});

const cssBreadcrumbLink = css({
  color: `#3c4d68`,
  borderBottom: `none`,
});

const Tags = ({ pageContext, data }) => {
  const { edges } = data.allMarkdownRemark;
  const { html, frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <g.Div id="page-wrap" margin={`0 auto`} backgroundColor={`#fcfcfc`}>
        <Helmet>
          <title>{frontmatter.metaTitle}</title>

          <meta name="description" content={frontmatter.metaDescription} />
          <html lang="en" />
          {frontmatter.pathFr !== "/" && (
            <link
              rel="alternate"
              hreflang="fr"
              href={`https://www.dr-kron.com${frontmatter.pathFr}`}
            />
          )}
          {/* Breadcrumbs for corps */}
          {frontmatter.tags[0] === "corps" && (
            <script type="application/ld+json">{` {
              "@context": "http://schema.org",
              "@type": "BreadcrumbList",
    "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": "${
        frontmatter.shortTitle.charAt(0).toUpperCase() +
        frontmatter.shortTitle.slice(1)
      }",
      "item": "https://www.dr-kron.com${frontmatter.path}"
    }]
          }
        `}</script>
          )}
          {/* Breadcrumbs for traitements */}
          {frontmatter.tags[0] === "traitements" && (
            <script type="application/ld+json">{` {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "name": "
    ${
      frontmatter.shortTitle.charAt(0).toUpperCase() +
      frontmatter.shortTitle.slice(1)
    }
    ",
    "item": "https://www.dr-kron.com${frontmatter.path}"
  }]
        }
      `}</script>
          )}

          {/* Breadcrumbs for cabinet */}
          {frontmatter.tags[0] === "cabinet" && (
            <script type="application/ld+json">{` {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "name": "${frontmatter.title}",
    "item": "https://www.dr-kron.com${frontmatter.path}"
  }]
        }
      `}</script>
          )}

          {/* Breadcrumbs for avant-apres */}
          {frontmatter.shortTitle === "before-after" && (
            <script type="application/ld+json">{` {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Before & After pictures of plastic surgery",
        "item": "https://www.dr-kron.com/en/before-after/"
        }]
            }
  `}</script>
          )}
        </Helmet>
        <Menu pathFr={frontmatter.pathFr} />
        <Wrapper>
          <article>
            <Container>
              <H1>{frontmatter.title}</H1>

              {/* Breadcrumbs for corps */}
              {frontmatter.tags[0] === "corps" && (
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to="/" {...cssBreadcrumbLink} title="Page d'accueil">
                      <FaHome
                        css={{
                          fontSize: `1.2em`,
                          paddingBottom: `.2em`,
                          transform: `translateY(5px)`,
                        }}
                      />
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link to="/interventions/" {...cssBreadcrumbLink}>
                      Interventions
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    {frontmatter.shortTitle.charAt(0).toUpperCase() +
                      frontmatter.shortTitle.slice(1)}
                  </BreadcrumbItem>
                </Breadcrumb>
              )}
              {/* Breadcrumbs for techniques */}
              {frontmatter.tags[0] === "traitements" && (
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to="/" {...cssBreadcrumbLink} title="Page d'accueil">
                      <FaHome
                        css={{
                          fontSize: `1.2em`,
                          paddingBottom: `.2em`,
                          transform: `translateY(5px)`,
                        }}
                      />
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link to="/techniques/" {...cssBreadcrumbLink}>
                      Techniques
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    {frontmatter.shortTitle.charAt(0).toUpperCase() +
                      frontmatter.shortTitle.slice(1)}
                  </BreadcrumbItem>
                </Breadcrumb>
              )}
              {/* Breadcrumbs for avant-apres */}
              {frontmatter.shortTitle === "before-after" && (
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to="/" {...cssBreadcrumbLink} title="Homepage">
                      <FaHome
                        css={{
                          fontSize: `1.2em`,
                          paddingBottom: `.2em`,
                          transform: `translateY(5px)`,
                        }}
                      />
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>Before & After</BreadcrumbItem>
                </Breadcrumb>
              )}
              <div
                dangerouslySetInnerHTML={{ __html: html }}
                className="intro"
                css={{
                  marginBottom: `2em`,
                }}
              />
              <Row>
                {edges.map(({ node }) => {
                  const {
                    path,
                    shortTitle,
                    title,
                    excerpt,
                    image,
                  } = node.frontmatter;
                  return (
                    <Col
                      span={{ xs: 12 / 12, md: 1 / 2, lg: 1 / 3 }}
                      css={{ display: `flex` }}
                    >
                      <Card key={path}>
                        {image && (
                          <Link to={path}>
                            <Img
                              fluid={image.childImageSharp.fluid}
                              alt={title}
                              css={{
                                marginBottom: `.5em`,
                                borderRadius: `6px 6px 0 0`,
                              }}
                            />
                          </Link>
                        )}

                        <Link to={path}>
                          <Title>{shortTitle}</Title>{" "}
                        </Link>

                        <Text>{excerpt}</Text>
                      </Card>
                    </Col>
                  );
                })}
                <Col
                  span={{ xs: 12 / 12, lg: 1 / 3 }}
                  css={{ display: `flex` }}
                >
                  <Card
                    key="contact"
                    css={{
                      display: `flex`,
                      alignItems: `stretch`,
                    }}
                  >
                    <ContactForm about={frontmatter.about} />
                  </Card>
                </Col>
              </Row>
            </Container>
          </article>
        </Wrapper>
        <Footer />
      </g.Div>
    </Layout>
  );
};

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              path: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
              excerpt: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};

export default Tags;

export const pageQuery = graphql`
  query TagPageEn($tag: String) {
    allMarkdownRemark(
      limit: 500
      sort: { fields: [frontmatter___shortTitle], order: ASC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            shortTitle
            path
            excerpt
            image {
              childImageSharp {
                resize(width: 1500, height: 1500) {
                  src
                }
                fluid(maxWidth: 367) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
    markdownRemark(frontmatter: { shortTitle: { eq: $tag } }) {
      frontmatter {
        title
        shortTitle
        metaTitle
        metaDescription
        about
        tags
        path
        pathFr
      }
      html
    }
  }
`;
